<div class="heading">
	<h1>Doctors Finest</h1>
	<h2>Alcohol By Volume</h2>
					
</div>

<div class="section-container">
	<h3>Whatcha making, Chief?</h3>
	<div class="input-container">
		<input
			type="text"
			placeholder="Name"
			autocomplete="off"
			[(ngModel)]="drink.name"
			(keyup.enter)="pageGoto('contents')"/>
	</div>
</div>

<div class="section-container" *ngIf="drink.name !== ''" #drinkContents>
	<h3 class="right">... and what's in that?</h3>
	<form
		[formGroup]="addDrinkForm"
		(ngSubmit)="onDrinkSubmit(addDrinkForm.value)">
		<div class="input-container">
			<label>Ingredient</label>
			<input
				#ingredientInput
				type="text"
				autocomplete="off"
				[formControl]="addDrinkForm.controls['ingredient']"/>
		</div>
		<div class="input-container">
			<label>ABV (percent)</label>
			<input
				type="number"
				autocomplete="off"
				placeholder="Eg. '40'"
				[formControl]="addDrinkForm.controls['abv']"/>
		</div>
		<div class="input-container">
			<label>Amount (ml)</label>
			<input
				type="number"
				autocomplete="off"
				[formControl]="addDrinkForm.controls['amount']"/>
		</div>
		<div *ngIf="!addDrinkForm.valid && addDrinkForm.touched">
			<p class="error tiny" *ngIf="ingredient.hasError('required')">Ingredient is required.</p>

			<div *ngIf="!abv.valid && abv.touched">
				<p class="error tiny" *ngIf="abv.hasError('required')">ABV is required.</p>

				<div *ngIf="abv.hasError('pattern'); else abvRangeCheck">
					<p class="error tiny">ABV must be a number</p>
				</div>
				<ng-template #abvRangeCheck>
					<p class="error tiny" *ngIf="abv.hasError('min')">
						ABV must be a number in range [1, 100]
					</p>
					<p class="error tiny" *ngIf="abv.hasError('max')">
						ABV cannot be greater than 100%
					</p>
				</ng-template>
			</div>

			<div *ngIf="!amount.valid && amount.touched">
				<p class="error tiny" *ngIf="amount.hasError('required')">Amount is required.</p>
				<p class="error tiny" *ngIf="amount.hasError('min') || amount.hasError('pattern')">
					Amount must be a number greater than 1
				</p>
			</div>
		</div>
		<button class="button" type="submit">Add</button>
	</form>

	<div *ngIf="drink.contents.length > 0" #drinkInfo>
		<div class="section-container">
			<h3>Alright, here you go:</h3>
			<h1>'{{ drink.name }}'</h1>
			<div class="drink-contents-container">
				<div
					class="drink-content"
					*ngFor='let liquor of drink.contents; index as idx'
					(click)="removeLiquorIdx(idx)">
						<object type="image/svg+xml" data="/assets/flask.svg" class="flask"></object>
						<p>{{ liquor.amount }}ml x {{ liquor.name }}</p>
				</div>
				<p class="tiny center">[Tap part to remove]</p>
			</div>
		</div>

		<div class="section-container">
			<h3 class="mono">Stats</h3>
			<div class="indent">
				<p>{{ getRounded(drink.getAlcoholGrams()) }}g of Alcohol</p>
				<p>{{ getRounded(drink.getStandardDrinks()) }} Standard Drinks</p>
				<p>{{ getRounded(drink.getAbv()) }}% Alcohol</p>
				<p>{{ getRounded(drink.getMl()) }} liquid ml</p>
			</div>
		</div>
	</div>
</div>

